/* !
 * index.js v1.0.0
 * Copyright, Connecty Inc.
 */
((FUNCTIONS) => {
	const a = 'is-active';
	const v = 'is-visible';

	const initSearch = () => {
		if (document.querySelector('.js-faq-search-submit')) {
			document.querySelector('.js-faq-search-submit').addEventListener('click', function (event) {

				submitSearch();
			});
		}
	}

	const submitSearch = () => {
		let queryString = "";
		// faq_keyword
		const input = document.querySelector('input[data-name="faq_keyword"]').value;
		const keywords = input.trim().split(/\s+/).filter(Boolean);
		const limitedKeywords = keywords.slice(0, 5);
		if (limitedKeywords.length > 0) {
			queryString += limitedKeywords.map((keyword) => `faq_keyword=${encodeURIComponent(keyword)}`).join('&');
		}

		// faq_prodcut
		let selectedCheckboxes = document.querySelectorAll('input[name="faq_prodcut"][type="checkbox"]:checked');
		let selectedValues = Array.from(selectedCheckboxes).map(checkbox => checkbox.value);
		if (selectedValues.length > 0) {
			if (queryString !== "") {
				queryString += "&"

			}
			queryString += selectedValues.map(category => `faq_prodcut=${encodeURIComponent(category)}`).join('&')

		}

		// faq_purpose
		selectedCheckboxes = document.querySelectorAll('input[name="faq_purpose"][type="checkbox"]:checked');
		selectedValues = Array.from(selectedCheckboxes).map(checkbox => checkbox.value);
		if (selectedValues.length > 0) {
			if (queryString !== "") {
				queryString += "&"
			}
			queryString += selectedValues.map(category => `faq_purpose=${encodeURIComponent(category)}`).join('&')
		}

		// ページ遷移
		const baseUrl = window.location.origin;
		const newUrl = `${baseUrl}/faq/search/?${queryString}`;
		window.location.href = newUrl;
	}

	const resetSearchInput = () => {
		if (document.querySelector('.js-search-reset')) {
			document.querySelector('.js-search-reset').addEventListener('click', function (event) {
				event.preventDefault();  // リンクのデフォルト動作を無効化

				// テキストボックスをリセット
				var textInputs = document.querySelectorAll('input[type="text"]');
				textInputs.forEach(function (input) {
					input.value = '';
				});

				// checkboxをリセット
				var checkboxes = document.querySelectorAll('input[type="checkbox"]');
				checkboxes.forEach(function (checkbox) {
					checkbox.checked = false; // チェックを外す
				});
			});
		}
	}

	const setFaqKeywords = () => {

		if (document.querySelector('input[data-name="faq_keyword"]')) {
			const params = new URLSearchParams(window.location.search);
			const faqKeywords = params.getAll('faq_keyword');
			const combinedKeywords = faqKeywords.join(' ');
			const faqInput = document.querySelector('input[data-name="faq_keyword"]');
			if (faqKeywords.length > 0 && faqInput) {
				faqInput.value = combinedKeywords;
			}
		}
	};

	const toggleFixedBnr = () => {
		const footer = document.querySelector('.footer'); // フッター要素
		const nav = document.querySelector('.faq-fixed-nav'); // 追従要素（高さ100px）

		if (nav) {

			// 現在のURLを取得
			const currentPath = window.location.pathname;

			// `/faq/index.html` 以外で処理を実行
			if (currentPath !== '/faq/' && currentPath !== '/faq/index.html') {
				// `faq-fixed-nav` 内の `rel="scroll"` を持つ全ての `<a>` 要素を取得
				const scrollLinks = document.querySelectorAll('.faq-fixed-nav a[rel="scroll"]');
		
				// 各リンクから `rel="scroll"` を削除
				scrollLinks.forEach(link => {
					link.removeAttribute('rel');
				});
			}

			const footerHeight = 100; // navの高さを考慮
			const footerPos = footer.offsetTop; // フッターの上端位置
			const stopPos = footerPos - footerHeight; // 削除する位置を調整
			let isThrottled = false; // スクロール処理を間引くためのフラグ

			window.addEventListener('scroll', () => {
				if (!isThrottled) {
					isThrottled = true;
					requestAnimationFrame(() => {
						const scrollY = window.scrollY; // 現在のスクロール位置

						if (scrollY + window.innerHeight >= stopPos) {
							// スクロール位置がフッター手前に到達した場合
							nav.classList.remove(v); // クラス`v`を削除して追従を停止
						} else {
							// フッター手前より上にいる場合
							nav.classList.add(v); // クラス`v`を追加して追従を継続
						}

						isThrottled = false; // 次のスクロール処理を許可
					});
				}
			});
		}
	}
	
	if(document.querySelector('.cf_f_related_faq')) {
		document.querySelectorAll('.cf_f_related_faq').forEach(elm => {
			if (!elm.textContent.trim()) { // 中身が空かを確認
				elm.classList.add('di_none'); // クラスを追加
			}
		});
	}
	
	toggleFixedBnr();

	setFaqKeywords();
	setTimeout(setFaqKeywords, 1000)
	initSearch();
	resetSearchInput();

})(window.FUNCTIONS);
